import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import "../utils/css/custom.css"

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const Edition = ({ data, pageContext }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout title={siteTitle} page="edition">
      <SEO
        title={pageContext.edition}
        keywords={[
          `blog`,
          `samratchana`,
          `mahajyothi`,
          `baba`,
          `magazine`,
          `siva shankar baba`,
        ]}
      />
      {/* <Bio /> */}
      <header className="page-head">
        <h4 className="page-head-title">{pageContext.edition}</h4>
      </header>
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
      <div className="row">
        <div className="col link-block">
          <Link
            disabled={!pageContext.nextUrl}
            className={pageContext.nextUrl ? "" : "disabled-link"}
            to={"/" + pageContext.nextUrl}
          >
            &lsaquo; Previous
          </Link>
          <h6>{pageContext.next}</h6>
        </div>
        <div className="col link-block">
          <Link
            disabled={!pageContext.previousUrl}
            className={pageContext.previousUrl ? "" : "disabled-link"}
            to={"/" + pageContext.previousUrl}
          >
            Next &rsaquo;
          </Link>
          <h6>{pageContext.previous}</h6>
        </div>
      </div>
      {/* <div className="col link-block">
          <Link disabled={!pageContext.nextUrl}
            className={pageContext.nextUrl ? "" : "disabled-link"}
            to={'/' + pageContext.nextUrl}>Random &rsaquo;</Link>
        </div> */}
    </Layout>
  )
}

export default Edition

export const pageQuery = graphql`
  query($edition: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { edition: { eq: $edition } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

// export default props => (
//   <StaticQuery
//     query={indexQuery}
//     render={data => (
//       <Edition location={props.location} props data={data} {...props} />
//     )}
//   />
// )
